body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center ul {
  /* display: flex; */
  padding-inline-start: 0 !important;
  width: 150%;
}

.center ul li {
  font-size: 11px;
  color: #737373;
  margin: 0 10px;
  word-wrap: unset;
}

.center ul li.done {
  color: #2CDA9D;
  list-style-type: none;
}

.center ul li.done::before {
  content: '\2713';
  margin-right: 3px;
}

@media only screen and (max-width: 480px) {
  .flexi-wizard-title{
    margin-left: -10px;
  }

  .flexi-wizard-wrapper {
    grid-template-columns: 1fr !important;
  }

  .flexi-wizard-progress {
    margin: -200px 0 0 58px;
    transform: rotate(-90deg);
  }

  .flexi-wizard-progress::before {
    margin: 0 50px 0 80px !important;
  }
  
  .flexi-wizard-progress ul {
    margin: 0 50px 0 80px !important;
  }

  .flexi-wizard-right {
    margin-top: -120px !important;
  }
}